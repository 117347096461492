import React from "react";

import ArticleTemplate from "../../../../../lib/templates/article";
import { SectionType } from "../../../../../lib/templates/article/typings";

import { SectionEventHeaderContentstackProps } from "../../../../../lib/sections/event/header/transforms/contentstack";

import { ContentstackSection } from "../../../articles/templates/default/typings";
import Meta from "../../../../../lib/components/meta";
import { openGraphImageUrl, twitterImageUrl } from "../../../../../lib/assets/share";
import { TemplateContext } from "./typings";
import { dataSectionsToArticleSections } from "../../../articles/templates/default";
import { ThemeProvider } from "styled-components";
import { useMetaTitle } from "../../../../utils/use-meta-title";
import { useIntl } from "gatsby-plugin-intl";
import { graphql, PageProps } from "gatsby";
import transformAllPagesQueryNodeToDefaultTemplateContextData from "../../transforms/all-pages-query-node-to-default-template-context-data";
import { Helmet } from "react-helmet";
// TODO: EmailFormPageConfig should be in data.all.nodes
import { /* EmailFormPageConfig */ EmailFormGlobalConfig } from "@riotgames/wwpub-components";
import { SectionEventFooterProps } from "../../../../../lib/sections/event/footer";

interface PageData {
  // TODO: unravel types.
  all: {
    nodes: any;
  };
  configuration: { edges: Array<{ node: { email_form: EmailFormGlobalConfig } }> };
}

type Props = PageProps<PageData, TemplateContext>;
const byPublishedLocale =
  (locale: string) =>
  (node: any): boolean =>
    node.publish_details.locale === locale;

const ContentType: React.FC<Props> = ({ data: __data, pageContext }) => {
  const language = pageContext.language;
  const byContextLocale = byPublishedLocale(language);
  const node = __data.all.nodes.filter(byContextLocale)[0];
  const emailConfigurationNode = __data.configuration.edges[0].node.email_form;
  const data = transformAllPagesQueryNodeToDefaultTemplateContextData(node, emailConfigurationNode, language);

  // Inject a header section into the configured sections from contentstack
  const headerProps: SectionEventHeaderContentstackProps = {
    imageUrl: data.imageUrl,
    bannerSettings: data.banner_settings,
    blurb: data.description,
    title: data.title,
  };

  const footerProps: SectionEventFooterProps = {
    footer_video_enabled: data.footer_settings?.footer_video_enabled,
    footer_html5_video_url: data.footer_settings?.footer_html5_video_url,
    footer_mobile_html5_video_url: data.footer_settings?.footer_mobile_html5_video_url,
  };

  const headerSection: ContentstackSection = {
    type: SectionType.EVENT_HEADER,
    props: headerProps,
  };
  const footerSection: ContentstackSection = {
    type: SectionType.EVENT_FOOTER,
    props: footerProps,
  };
  const intl = useIntl();
  const quotationMarkClose = intl.formatMessage({ id: "quotation-mark.close" });
  const sections = [headerSection, ...data.sections, footerSection];
  const configuredTheme = data.theme_configuration || {};
  const theme = { ...{ quotation_mark_close: quotationMarkClose }, ...configuredTheme };

  const fontCSSLinks = [];

  if (theme?.font_options?.body_font?.font_filename) {
    fontCSSLinks.push(theme.font_options.body_font.font_filename);
  }

  if (theme?.font_options?.header_font?.font_filename) {
    fontCSSLinks.push(theme.font_options.header_font.font_filename);
  }

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Meta
          title={useMetaTitle(data.title)}
          openGraph={{
            image: {
              url: data.imageUrl ? data.imageUrl : openGraphImageUrl,
            },
          }}
          twitter={{
            image: {
              url: data.imageUrl ? data.imageUrl : twitterImageUrl,
            },
          }}
        />
        {fontCSSLinks.length > 0 && (
          <Helmet>
            {fontCSSLinks.map((fontLink, i) => {
              return <link key={i} rel="stylesheet" href={fontLink} />;
            })}
          </Helmet>
        )}
        <ArticleTemplate sections={dataSectionsToArticleSections(sections)}></ArticleTemplate>
      </React.Fragment>
    </ThemeProvider>
  );
};
export default ContentType;

export const query = graphql`
  # If the page query cache needs to change with context changes, then we need
  # $path and a query for contextChangeNotInvalidingQueryWorkaround
  # Since this page only uses locale and url, and those are essentially the query
  # cache key anyways, then we don't need to add this hack.
  #
  # query($language: String!, $url: String!, $path: String!) {
  # use our workaround and alias null result to something short (_)
  # that doesn't expose what it does and saves few bytes
  #_: contextChangeNotInvalidingQueryWorkaround(path: $path)

  query ($language: String!, $url: String!) {
    all: allContentstackPages(filter: { publish_details: { locale: { eq: $language } }, url: { url: { eq: $url } } }) {
      nodes {
        publish_details {
          locale
        }
        uid
        url {
          url
        }
        publish_details {
          time
        }
        title
        banner_settings {
          banner {
            url
          }
          focal_point_x
          focal_point_y
          youtube_video_id
          banner_type
          html5_video {
            video {
              file {
                url
              }
            }
          }
          mobile_html5_video {
            video {
              file {
                url
              }
            }
          }
        }
        footer_settings {
          footer_video_enabled
          footer_html5_video {
            video {
              file {
                url
              }
            }
          }
          footer_mobile_html5_video {
            video {
              file {
                url
              }
            }
          }
        }
        theme_configuration {
          background_color
          background_image {
            url
          }
          background_image_mobile {
            url
          }
          background_image_repeats
          header_font_color
          paragraph_font_color
          primary_button_settings {
            font_color
            background_color
          }
          secondary_button_settings {
            font_color
            background_color
          }
          font_options {
            header_font {
              font_filename
              font_family
            }
            body_font {
              font_filename
              font_family
            }
          }
          email_form_settings {
            button_color
            background_color
            background_image {
              url
            }
          }
        }
        article_body {
          accordion {
            accordion_configuration {
              accordion_title
              accordion_description
            }
          }
          audio {
            audio_file {
              url
            }
            caption
          }
          author_bio {
            author_selection {
              author_bio
              job_title
              full_name
              title
              avatar {
                url
              }
            }
          }
          call_out {
            body
            image {
              url
            }
            external_link
            open_in_new_window
          }
          html5_video {
            autoplay
            controls
            html5_video_selection {
              title
              video {
                file {
                  url
                  content_type
                }
                height
                width
              }
            }
          }
          image_gallery {
            title
            image_selection {
              image_title
              select_image {
                url
              }
            }
          }
          images {
            images {
              image {
                url
              }
              caption
              title
              value
              external_link
              open_in_new_window
            }
          }
          media_carousel {
            introduction
            items {
              title
              description
              thumbnail {
                uid
                url
              }
              image {
                uid
                url
              }
              html5_video {
                title
                static_image {
                  url
                }
                video {
                  file {
                    url
                    content_type
                  }
                }
              }
              youtube_video_id
            }
          }
          promo_module {
            promo_module_selection {
              uid
              promo_title
              subtitle
              description
              hide_title
              include_description
              promo_reference {
                __typename
                ... on Contentstack_articles {
                  ...ContentstackArticleExcerpt
                }
              }
              promo_full_width_banner {
                url
                uid
              }
            }
          }
          rich_text_editor {
            rich_text_editor
            side_image {
              order
              image {
                url
              }
            }
          }
          separator {
            type
            custom_divider {
              url
            }
          }
          video_youtube {
            video_id
          }
          tweet_embed {
            tweet {
              tweet_id
            }
          }
          tabs {
            tab_configuration {
              tab_content
              tab_name
            }
          }
          cta_list {
            items {
              link {
                href
                title
              }
              style
            }
          }
          email_form {
            ...EmailFormPageConfigData
          }
        }
      }
    }
    configuration: allContentstackWebsiteConfiguration(filter: { publish_details: { locale: { eq: $language } } }) {
      edges {
        node {
          email_form {
            ...EmailFormGlobalConfigData
          }
        }
      }
    }
  }
`;
