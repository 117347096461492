import { TemplateContextData, TemplateThemeFontOptions, TemplateEmailFormSettings } from "../templates/default/typings";
import { ContentstackAllPagesQuery as Query } from "../../../../generated/graphql";
import { ImageAssetUrl, ImageAsset } from "../../assets/typings";
import notEmpty from "../../../utils/not-empty";
import { ContentstackSection } from "../../articles/templates/default/typings";
import { articleBodySectionToContentstackSection } from "../../articles/transforms/all-internal-articles-query-node-to-default-template-context-data";

const transformAllEventsQueryNodeToDefaultTemplateContextData = (
  node: Query["all"]["edges"][0]["node"],
  emailConfigurationNode: Query["configuration"]["edges"][0]["node"]["email_form"],
  locale: string,
): TemplateContextData => {
  // transform sections
  const sections: ContentstackSection[] = !node.article_body
    ? []
    : node.article_body
        .map((bodySection) =>
          articleBodySectionToContentstackSection(bodySection, node, emailConfigurationNode, locale),
        )
        .filter(notEmpty);

  const { theme_configuration, banner_settings, footer_settings } = node;
  const { primary_button_settings, secondary_button_settings } = theme_configuration;

  return {
    uid: node.uid,
    title: node.title,
    imageUrl: (banner_settings && banner_settings.banner && (banner_settings.banner.url as ImageAssetUrl)) || undefined,
    banner_settings: {
      banner: (banner_settings && (banner_settings.banner as ImageAsset)) || undefined,
      focal_point_x: (banner_settings && banner_settings.focal_point_x) || undefined,
      focal_point_y: (banner_settings && banner_settings.focal_point_y) || undefined,
      youtube_video_id: (banner_settings && banner_settings.youtube_video_id) || undefined,
      banner_type: (banner_settings && banner_settings.banner_type) || undefined,
      html5_video_url:
        (banner_settings &&
          banner_settings.html5_video &&
          banner_settings.html5_video[0] &&
          banner_settings.html5_video[0].video &&
          banner_settings.html5_video[0].video.file &&
          banner_settings.html5_video[0].video.file.url) ||
        undefined,
      mobile_html5_video_url:
        (banner_settings &&
          banner_settings.mobile_html5_video &&
          banner_settings.mobile_html5_video[0] &&
          banner_settings.mobile_html5_video[0].video &&
          banner_settings.mobile_html5_video[0].video.file &&
          banner_settings.mobile_html5_video[0].video.file.url) ||
        undefined,
    },
    footer_settings: {
      footer_video_enabled: (footer_settings && footer_settings.footer_video_enabled) || undefined,
      footer_html5_video_url:
        (footer_settings &&
          footer_settings.footer_html5_video &&
          footer_settings.footer_html5_video[0] &&
          footer_settings.footer_html5_video[0].video &&
          footer_settings.footer_html5_video[0].video.file &&
          footer_settings.footer_html5_video[0].video.file.url) ||
        undefined,
      footer_mobile_html5_video_url:
        (footer_settings &&
          footer_settings.footer_mobile_html5_video &&
          footer_settings.footer_mobile_html5_video[0] &&
          footer_settings.footer_mobile_html5_video[0].video &&
          footer_settings.footer_mobile_html5_video[0].video.file &&
          footer_settings.footer_mobile_html5_video[0].video.file.url) ||
        undefined,
    },
    theme_configuration: theme_configuration && {
      background_color: theme_configuration.background_color || undefined,
      header_font_color: theme_configuration.header_font_color || undefined,
      paragraph_font_color: theme_configuration.paragraph_font_color || undefined,
      primary_button_settings: primary_button_settings
        ? {
            font_color: primary_button_settings.font_color || undefined,
            background_color: primary_button_settings.background_color || undefined,
          }
        : {},
      secondary_button_settings: secondary_button_settings
        ? {
            font_color: secondary_button_settings.font_color || undefined,
            background_color: secondary_button_settings.background_color || undefined,
          }
        : {},
      font_options: (theme_configuration?.font_options as TemplateThemeFontOptions) || undefined,
      background_image: (theme_configuration && (theme_configuration.background_image as ImageAsset)) || undefined,
      background_image_mobile:
        (theme_configuration && (theme_configuration.background_image_mobile as ImageAsset)) || undefined,
      background_image_repeats: (theme_configuration && theme_configuration.background_image_repeats) || false,
      email_form_settings: theme_configuration.email_form_settings as TemplateEmailFormSettings,
    },
    sections,
  };
};

export default transformAllEventsQueryNodeToDefaultTemplateContextData;
